import React, {Component} from 'react';
import intl from 'react-intl-universal';

class Sobre extends Component {

	render(){
		return(
			<article className="servico">
				<img src="./img/img02c.jpg" alt="img02c.jpg" />
				<div className="inner" id="sobre">
					<h3 className="txt03"> {intl.get('txt03.txt03')} </h3>
					<h4 className="txt04"> {intl.get('txt04.txt04')} </h4>
					<p className="txt05"> {intl.getHTML('txt05.txt05')} </p>
				</div>
			</article>
		)
	}
}

export default Sobre;