import React, {Component} from 'react';
import intl from 'react-intl-universal';
import {FaLinkedin} from 'react-icons/fa';

class Rodape extends Component {

	render(){
		return(
			<div className="rodape">
				<div className="social-icons">
					<a href="https://www.linkedin.com/in/jean-carlos-alves" 
					target="_blank" rel="noopener noreferrer" ><FaLinkedin /></a>
					<p className="txt19"><img className="icone" 
					src="./img/Barcode2.png" alt="icon" />
						{intl.getHTML('txt19.txt19')}
					</p>
				</div>
				<div className="copyright">
					<p className="txt20">
						{intl.get('txt20.txt20')}
					</p>	
				</div>
			</div>		
		)
	}
}

export default Rodape;