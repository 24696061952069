import React, {Component} from 'react';
import './App.css';
import intl from 'react-intl-universal';
import {Switch, Route} from 'react-router-dom';
import MenuSuperior from './components/MenuSuperior';
import Inicio from './components/Inicio';
import Portfolio from './components/Portfolio';
import Sobre from './components/Sobre';
import Contato from './components/Contato';
import Rodape from './components/Rodape';


const locales = {
  'pt-BR': require('./locales/pt-BR.json'),
  'en-US': require('./locales/en-US.json'),
  'es-ES': require('./locales/es-ES.json')
};

class App extends Component {

  constructor(){
    super();

    const currentLocale = locales[navigator.language] ? navigator.language : 'pt-BR';

    intl.init({
      currentLocale,
      locales
    });
  }

  render(){
    return (
      <div className="App">
        <MenuSuperior/>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <Switch>
                <Route path="/" exact={true} component={Inicio}/>
                <Route path="/sobre" component={Sobre}/>
                <Route path="/portfolio" component={Portfolio}/>
                <Route path="/contato" component={Contato}/>
              </Switch>
            </div>
          </div>
        </div>
        <Rodape/>
      </div>
    );
  }
}
//<Route component={() => <div> Page 404! </div> }/>
export default App;