import React, {Component} from "react";
import ReactDOM from 'react-dom';
import App from './../App';
import {BrowserRouter, Link} from "react-router-dom";
import intl from 'react-intl-universal';
import {FiGlobe} from 'react-icons/fi';

var currentLocale = 'pt-BR';
const locales = {
  'pt-BR': require('./../locales/pt-BR.json'),
  'en-US': require('./../locales/en-US.json'),
  'es-ES': require('./../locales/es-ES.json')
};

class MenuSuperior extends Component {

	toggle(){
		var links = document.querySelectorAll('.nav-link');
		for (var i = 0; i < links.length; i++){
			links[i].addEventListener('click', function(event){
				var b = document.querySelector('.navbar-collapse');
				b.setAttribute('class', 'navbar-collapse collapse');
			}, false);
		}
	}

	tadutor(){
		if (currentLocale === 'pt-BR') {
			currentLocale = 'en-US';
		} else if (currentLocale === 'en-US') {
			currentLocale = 'es-ES';
		} else {
			currentLocale = 'pt-BR';
		}
		intl.init({
	      currentLocale,
	      locales
	    });
	    ReactDOM.render(
	    	<BrowserRouter>
				<App />
			</BrowserRouter>, document.getElementById('root')
    	);
	}

	render(){
		return(
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
				<Link to="/" className="navbar-brand"><img id="logo" src="./img/logo_branco01.png" alt="logo" /></Link>
				<div className="mobile">
					<Link to="#" id="trad" onClick={this.tadutor}><FiGlobe id="icone"/>
						{intl.get('#tradutor.tradutor')}
					</Link>
					<button id="btn" className="navbar-toggler" type="button" data-toggle="collapse" data-target=".collapse" aria-expanded="false" onClick={this.toggle}>
						<span className="navbar-toggler-icon"></span>
					</button>
				</div>
				<div className="collapse navbar-collapse" id="navbarNav">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<Link to="/" className="nav-link" id="m1">{intl.get('#m1.m1')}</Link>
						</li>
						<li className="nav-item">
							<Link to="/sobre" className="nav-link" id="m2">{intl.get('#m2.m2')}</Link>
						</li>
						<li className="nav-item">
							<Link to="/portfolio" className="nav-link" id="m3">{intl.get('#m3.m3')}</Link>
						</li>
						<li className="nav-item">
							<Link to="/contato" className="nav-link" id="m4">{intl.get('#m4.m4')}</Link>
						</li>
					</ul>
					<Link to="#" id="tradutor" onClick={this.tadutor}><FiGlobe id="icone"/>
						{intl.get('#tradutor.tradutor')}
					</Link>
				</div>
			</nav>
		)
	}
}

export default MenuSuperior;