import React, {Component} from 'react';
import intl from 'react-intl-universal';

class Inicio extends Component {

	render(){
		return(
			<article className="servico">
				<img src="./img/img01c.jpg" alt="img01c.jpg"/>
				<div className="inner">
					<h3 className="txt01"> {intl.get('txt01.txt01')} </h3>
					<h4> Scientia potentia est¹ </h4>
					<p className="txt02">
						{intl.getHTML('txt02.txt02')}
					</p>
					<p className="citacao1">
						{intl.getHTML('citacao1.citacao1')}
					</p>
					<p className="citacao2">
						{intl.getHTML('citacao2.citacao2')}
					</p>
				</div>
				<div>
					<article className="glossario">
						{intl.get('glossario.glossario')}
					</article>
				</div>
			</article>
		)
	}
}

export default Inicio;