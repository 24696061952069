import React, {Component} from 'react';
import intl from 'react-intl-universal';
import {FaUser, FaEnvelope, FaEdit, FaPencilAlt, FaPhone} from 'react-icons/fa';
import InputMask from 'react-input-mask';

class Contato extends Component {

	componentDidMount(){
		var btn = document.getElementById('btn2');
		btn.disabled = true;
	}

	verifica() {

		if (document.getElementById('nome').value !== "" &&
			document.getElementById('email').value !== "" && 
			document.getElementById('assunto').value !== "" && 
			document.getElementById('mensagem').value !== "") {
	    	var btn = document.getElementById('btn2');
	    	btn.disabled = false;
	    	btn.setAttribute('className', 'btn btn-primary')
		}
	}

	render(){
		return(
			<div className="contato">
				<h2 className="txt10"> {intl.get('txt10.txt10')} </h2>
				<h3 className="txt11"> {intl.get('txt11.txt11')} </h3>
				<h5 className="txt12"><i> {intl.get('txt12.txt12')} </i></h5>
				<form id="form" nome="form" action="/controllers/envia.php" method="POST" >
					<FaUser /><label id="f1"> {intl.get('#f1.f1')} </label><br/>
					<input type="text" name="nome" id="nome" placeholder={intl.get('#p1.p1')} onChange={this.verifica}/><br/>
					<FaEnvelope /><label id="f5"> {intl.get('#f5.f5')} </label><br/>
					<input type="email" name="email" id="email" placeholder={intl.get('#p2.p2')} onChange={this.verifica}/><br/>
					<FaPhone /><label id="f2"> {intl.get('#f2.f2')} </label><br/>
					<InputMask type="telefone" name="telefone" id="telefone" placeholder={intl.get('#p3.p3')} mask="(99) 99999-9999" maskChar=" " onChange={this.verifica}/><br/>
					<FaPencilAlt /><label id="f3"> {intl.get('#f3.f3')} </label><br/>
					<input type="text" name="assunto" id="assunto" placeholder={intl.get('#p4.p4')} onChange={this.verifica}/><br/>
					<FaEdit /><label id="f4"> {intl.get('#f4.f4')} </label><br/>
					<textarea name="mensagem" id="mensagem" placeholder={intl.get('#p5.p5')} onChange={this.verifica}></textarea><br/>
					<button id="btn2" className="btn btn-secondary"> {intl.get('enviar.enviar')} </button>
				</form>
			</div>
		)
	}
}

export default Contato;