import React, {Component} from 'react';
import intl from 'react-intl-universal';

class Portfolio extends Component {

	render(){
		return(
			<article className="servico">
				<img src="./img/img03c.jpg" alt="img03c.jpg" />
				<div className="inner" id="portfolio">
					<h3 className="txt06" id="portfolio"> {intl.get('txt06.txt06')} </h3>
					<h4 className="txt07"> {intl.get('txt07.txt07')} </h4>
					<p className="txt08">
						{intl.get('txt08.txt08')}
					</p>
				</div>
				<div className="portfolio">
					
				</div>
				<div className="sobre">
					<p className="txt09">
						{intl.get('txt09.txt09')}
					</p>
					<img src="./img/foto.jpg" alt="foto" />
				</div>
			</article>
		)
	}
}

export default Portfolio;